import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './login.css';

export default function Register() {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem("accessToken");
    
    const [formData, setFormData] = useState({
        fullname: "",
        username: "",
        email: "",
        password: "",
        confirm_password: ""
    });

    useEffect(() => {
        if (accessToken) {
            navigate("/");
        }
    }, [accessToken, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://api.avishifo.uz/api/user/register/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if (response.ok) {
                // Handle successful registration (e.g., navigate to login page)
                navigate("/login");
            } else {
                // Handle errors (e.g., display error message)
                console.error("Error:", data);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="loginPage">
            <div className="loginPage_box">
                <div className="loginPage_box_head">
                    <h4>Register to AviShifo</h4>
                </div>
                <p className="loginTitle">Enter your details to create your account.</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="fullname">Name</label>
                    <input
                        type="text"
                        name="fullname"
                        placeholder="John Doe"
                        value={formData.fullname}
                        onChange={handleChange}
                    />
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        name="username"
                        placeholder="johndoe"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="example@email.com"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="*******"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input
                        type="password"
                        name="confirm_password"
                        placeholder="*******"
                        value={formData.confirm_password}
                        onChange={handleChange}
                    />
                    <button type="submit">Register</button>
                </form>
                <div className="loginFooter">
                    <Link to="/login"><button>Login</button></Link>
                </div>
            </div>
        </div>
    );
}
