import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { LuPanelLeft, LuPen } from "react-icons/lu";
import { FaRegUser } from "react-icons/fa6";
import { IoSettingsOutline } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io'
import { BiLogOut } from 'react-icons/bi';
import Navbar from '../components/Navbar';
import axios from 'axios';
import './home.css';
import { FaChevronDown } from "react-icons/fa6";
import { sendMessageToAI } from './api'

export default function Layout({ history, getHistory }) {
    const [isOpenAside, setIsOpenAside] = useState(false);
    const [isOpenDrop, setIsOpenDrop] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(0);
    const [profilInfo, setProfilInfo] = useState([]);
    const [formValues, setFormValues] = useState({
        username: '',
        fullname: '',
        email: '',
        phone_number: '',
        gender: '',
        about: ''
    });

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const user_id = localStorage.getItem("user_id");

    const getProfilInfos = async () => {
        try {
            const response = await axios.get('https://api.avishifo.uz/api/user/profile/', {
                headers: {
                    Authorization: `Token ${accessToken}`
                }
            });
            setProfilInfo(response.data);
        } catch (error) {
            console.error("Error fetching profile info:", error);
        }
    };

    useEffect(() => {
        getProfilInfos();
    }, []);

    useEffect(() => {
        if (profilInfo.length > 0) {
            setFormValues({
                username: profilInfo[0].username,
                fullname: profilInfo[0].fullname,
                email: profilInfo[0].email,
                phone_number: profilInfo[0].phone_number,
                gender: profilInfo[0].gender,
                about: profilInfo[0].about
            });
        }
    }, [profilInfo]);

    const LogOut = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/login');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSaveProfile = () => {
        setIsOpenModal(0);
    };

    const newChat = async () => {
        try {
            // const emptyChat = history.find(item => item.title === null || item.title === 'Пустой чат');
            // if (emptyChat) {
            //     navigate(`/chat/${emptyChat.id}`);
            // } else {}
            const response = await axios.post(
                `https://api.avishifo.uz/api/chat_histories/create_user_history/`,
                { user_id: user_id },
                { headers: { Authorization: `Token ${accessToken}` } }
            );
            navigate(`/chat/${response.data.id}`);
            getHistory();

        } catch (error) {
            console.error("Error creating a new chat:", error);
        }
    };



    // lanuage select function ===================================================================================

    const [isOpen, setIsOpen] = useState(false);
    const languageCodes = {
        English: 'en',
        Russian: 'ru',
        Uzbek: 'uz',
        'Uzbek(kril)': 'kril',
    };

    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return localStorage.getItem('languageCode') ?
            Object.keys(languageCodes).find(key => languageCodes[key] === localStorage.getItem('languageCode')) : 'English';
    });


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        const languageCode = languageCodes[language];
        localStorage.setItem('languageCode', languageCode); // Store the language code
        newChat();
        getHistory();
    };


    // const postLanguageChange = async (language) => {
    //     const languageCode = languageCodes[language];
    //     const response = await sendMessageToAI(languageCode);
    // };    

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.laungeSelect')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    return (
        <>
            <Navbar
                profilInfo={profilInfo}
                isOpenDrop={isOpenDrop}
                setIsOpenModal={setIsOpenModal}
                isOpenModal={isOpenModal}
                setIsOpenDrop={setIsOpenDrop}
            />
            <div className="container">
                <button onClick={() => setIsOpenAside(true)} id='openAside'><LuPanelLeft /></button>
                <div className="main_chat_mobileHead">
                    <span>AviShifo</span>
                </div>
                {
                    !accessToken ? (
                        <div className='mobileLogin'>
                            <Link to="/login">Login</Link>
                            <Link to="/register">Register</Link>
                        </div>
                    ) : (
                        <>
                            <button onClick={() => setIsOpenDrop(!isOpenDrop)} className='profilDropBtn_mobile'><FaRegUser /></button>
                            <div className={isOpenDrop ? 'profilDropMenu' : 'none'}>
                                <button onClick={() => setIsOpenModal(1)}><FaRegUser /> Profil</button>
                                <button onClick={() => setIsOpenModal(3)}><IoSettingsOutline /> Setting</button>
                                <button onClick={() => LogOut()}><BiLogOut /> LogOut</button>
                            </div>
                        </>
                    )
                }
                {
                    isOpenModal === 1 && profilInfo.length > 0 &&
                    (
                        <div className="profilModal">
                            <div className="profilModal_box">
                                <div className="profilModal_box_head">
                                    <p>{profilInfo[0].username}</p>
                                    <button onClick={() => setIsOpenModal(false)}><IoMdClose /></button>
                                </div>
                                <div className="profilModal_box_userHeadInfo">
                                    <div className="profilModal_box_userHeadInfo_img">
                                        <img src={profilInfo[0].image} alt="" />
                                    </div>
                                    <div className="profilModal_box_userHeadInfo_info">
                                        <h4>{profilInfo[0].fullname}</h4>
                                        <p>{profilInfo[0].username}</p>
                                        <p>{profilInfo[0].email}</p>
                                        <p>{profilInfo[0].phone_number}</p>
                                        <p>{profilInfo[0].gender}</p>
                                    </div>
                                </div>
                                <div className="profilModal_box_main">
                                    <label htmlFor="">About</label>
                                    <p>{profilInfo[0].about}</p>
                                    <label htmlFor="">MEMBER SINCE</label>
                                    <p>June 2023</p>
                                    <label htmlFor="">FAVORITES</label>
                                    <p>Helping patients, Researching new treatments, Attending medical conferences</p>
                                    <label htmlFor="">SKILLS</label>
                                    <p>Diagnosis, Pharmacology, Patient care, Medical research</p>
                                </div>
                                <div className="profilModal_box_main_end">
                                    <button onClick={() => setIsOpenModal(2)}>Edit Profile</button>
                                    <button onClick={() => setIsOpenModal(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    isOpenModal === 2 && profilInfo.length > 0 &&
                    (
                        <div className="profilModal">
                            <div className="profilModal_box">
                                <div className="profilModal_box_head">
                                    <p>Edit Profile</p>
                                    <button onClick={() => setIsOpenModal(false)}><IoMdClose /></button>
                                </div>
                                <div className="profilModal_box_userHeadInfo">
                                    <div className="profilModal_box_userHeadInfo_img">
                                        <img src={profilInfo[0].image} alt="" />
                                    </div>
                                    <div className="profilModal_box_userHeadInfo_info">
                                        <input
                                            type="text"
                                            name="fullname"
                                            value={formValues.fullname}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type="text"
                                            name="username"
                                            value={formValues.username}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            value={formValues.email}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type="text"
                                            name="phone_number"
                                            value={formValues.phone_number}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            type="text"
                                            name="gender"
                                            value={formValues.gender}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="profilModal_box_main">
                                    <label htmlFor="">About</label>
                                    <input
                                        type="text"
                                        name="about"
                                        value={formValues.about}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="">MEMBER SINCE</label>
                                    <input
                                        type="text"
                                        name="about"
                                        value={"June 2023"}
                                    // onChange={handleInputChange} 
                                    />
                                    <label htmlFor="">FAVORITES</label>
                                    <input
                                        type="text"
                                        name="about"
                                        value={"Helping patients, Researching new treatments, Attending medical conferences"}
                                    // onChange={handleInputChange} 
                                    />
                                    <label htmlFor="">SKILLS</label>
                                    <input
                                        type="text"
                                        name="about"
                                        value={"Diagnosis, Pharmacology, Patient care, Medical research"}
                                    // onChange={handleInputChange} 
                                    />
                                </div>
                                <div className="profilModal_box_main_end">
                                    <button onClick={handleSaveProfile}>Save</button>
                                    <button onClick={() => setIsOpenModal(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    isOpenModal === 3 && profilInfo.length > 0 &&
                    (
                        <div className="profilModal">
                            <div className="profilModal_box">
                                <div className="profilModal_box_head">
                                    <p>{profilInfo[0].username}</p>
                                    <button onClick={() => setIsOpenModal(false)}><IoMdClose /></button>
                                </div>
                                <div className="profilModal_box_main">
                                    <div className="laungeSelect">
                                        <div className="laungeSelect_default" onClick={toggleDropdown}>
                                            <span>Language</span>
                                            <span>{selectedLanguage} <FaChevronDown /></span> {/* Show selected language */}
                                        </div>
                                        {isOpen && (
                                            <div className="lengs">
                                                {Object.keys(languageCodes).map((language) => (
                                                    <span key={language} onClick={() => handleLanguageSelect(language)}>
                                                        {language}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }
                <aside className={!isOpenAside ? 'close' : null}>
                    <div className="asideTopMenu">
                        <button onClick={() => setIsOpenAside(false)}><LuPanelLeft /></button>
                        <button onClick={() => newChat()}><LuPen /></button>
                    </div>
                    <div className="asideChatData">
                        <p className="asideChatData_title">History</p>
                        <div>
                            {history.map(item => (
                                <p onClick={() => navigate(`/chat/${item.id}`)} key={item.id}>{item.title !== null ? item.title : 'Пустой чат'}</p>
                            ))}
                        </div>
                    </div>
                </aside>
                <div className="main">
                    <Outlet />
                </div>
            </div>
        </>
    );
}
