import React, { useState, useEffect, useRef } from 'react';
import logo from '../image/logoPng.png';
import { FaArrowUp } from "react-icons/fa6";
import { sendMessageToAI } from './api';
import { MdOutlineAttachFile } from "react-icons/md";
import axios from 'axios';
import DOMPurify from 'dompurify';
import './home.css';
import { useParams } from 'react-router-dom';
import TypingEffect from './TypingEffect';

export default function Chat({ getHistory }) {
    const { historyId } = useParams();
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [chat, setChat] = useState([]);
    const [hisId, setHisId] = useState(historyId);
    const chatEndRef = useRef(null);
    const textareaRef = useRef(null);
    const mainChatRef = useRef(null);
    const [latestAviShifoResponse, setLatestAviShifoResponse] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setHisId(historyId);
        getChat(historyId);
    }, [historyId]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        setIsSending(true);
        setIsLoading(true);
        const newChat = [...chat, { sender: 'You', text: DOMPurify.sanitize(message) }];
        setChat(newChat);
        setMessage('');
        textareaRef.current.style.height = '25px'; 
        const response = await sendMessageToAI(message, hisId);
        const sanitizedResponse = DOMPurify.sanitize(response);
        setChat([...newChat, { sender: 'AviShifo', text: sanitizedResponse }]);
        setLatestAviShifoResponse(sanitizedResponse);
        setIsSending(false);
        setIsLoading(false);
        setFile(null);
        // getHistory();
    };
    const accessToken = localStorage.getItem('accessToken');

    const welcomeMessages = {
        en: "Welcome, esteemed doctor! I am here to become your faithful assistant in treating patients. To begin, I ask you to provide subjective assessments, objective findings, and laboratory data based on the patient's medical history. Together, we can achieve the best results in their recovery. Your professionalism and our collaboration will be the key to successful therapy. We are a united team in the fight for health!",
        ru: 'Добро пожаловать, уважаемый доктор! Я здесь, чтобы стать вашим верным помощником в лечении пациентов. Для начала прошу вас предоставить субъективные оценки, объективные находки и лабораторные данные на основе историй болезни пациента. Вместе мы сможем достичь наилучших результатов в их выздоровлении. Ваш профессионализм и наше сотрудничество станут залогом успешной терапии. Мы — единая команда в борьбе за здоровье!',
        uz: "Xush kelibsiz, hurmatli doktor! Men bu yerda bemorlarni davolashda sizning sodiq yordamchingiz bo'lish uchun keldim. Avvalo, sizdan sub'ektiv baholarni, ob'ektiv topilmalarni va bemorning kasallik tarixi asosida laboratoriya ma'lumotlarini taqdim etishingizni so'rayman. Birgalikda ularning sog'ayishi uchun eng yaxshi natijalarga erisha olamiz. Sizning professionalligingiz va bizning hamkorligimiz muvaffaqiyatli davolashning kafolatidir. Biz — sog'liq uchun kurashda yagona jamoamiz!",
        kril: "Хуш келибсиз, ҳурматли доктор! Мен бу ерда беморларни даволашда сизнинг содиқ ёрдамчингиз бўлиш учун келдим. Аввало, сиздан субъектив баҳоларни, объектив топилмаларни ва беморнинг касаллик тарихи асосида лаборатория маълумотларини тақдим этишингизни сўрайман. Биргаликда уларнинг соғайиши учун энг яхши натижаларга эриша оламиз. Сизнинг профессионаллилигингиз ва бизнинг ҳамкорлигимиз муваффақиятли терапиянинг кафолатидир. Биз — соғлиқ учун курашда ягона жамоамиз!"
    };
    
    const getChat = async (historyId) => {
        try {
            const response = await axios.get(`https://api.avishifo.uz/api/get/msg/?history_id=${historyId}`, {
                headers: {
                    Authorization: `Token ${accessToken}`
                }
            });
            const chatHistory = response.data.map(item => [
                { sender: 'You', text: DOMPurify.sanitize(item.user_message) },
                { sender: 'AviShifo', text: DOMPurify.sanitize(item.bot_response) }
            ]).flat();
    
            if (chatHistory.length === 0) {
                const languageCode = localStorage.getItem('languageCode') || 'ru'; // Default to 'ru' if no language is set
                const welcomeMessage = welcomeMessages[languageCode];
                chatHistory.push({ sender: 'AviShifo', text: welcomeMessage });
            }
    
            setChat(chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };
    

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chat]);

    const handleTypingEffectFinish = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const adjustMainChatHeight = () => {
        if (mainChatRef.current && textareaRef.current) {
            const textareaHeight = Math.min(textareaRef.current.scrollHeight, 200); // Limit the height to 200px
            mainChatRef.current.style.height = `calc(80vh - ${textareaHeight}px)`;
        }
    };

    useEffect(() => {
        adjustMainChatHeight();
    }, [message]);

    const handleChange = (e) => {
        setMessage(e.target.value);


        if (textareaRef.current) {
            textareaRef.current.style.height = '25px'; // Reset height
            const newHeight = Math.min(textareaRef.current.scrollHeight, 200); // Limit the height to 200px
            textareaRef.current.style.height = `${newHeight}px`; // Set height to match content within the limit
        } 
        adjustMainChatHeight();
    };

    return (
        <div className="main_chat" ref={mainChatRef}>
            {chat.map((chat, index) => (
                <div key={index} className="main_chat_data">
                    <div className="main_chat_data_userImg">
                        <img 
                            src={chat.sender === 'You' 
                                ? 'https://i.pinimg.com/originals/76/b7/bf/76b7bfddee29f2bc0000cbae02b0c629.png' 
                                : logo} 
                            alt="" 
                        />
                    </div>
                    <div className="main_chat_data_chat">
                        <span className="main_chat_data_chat_head">{chat.sender}</span>
                        <div className="main_chat_data_chat_main">
                            {chat.sender === 'AviShifo' && chat.text === latestAviShifoResponse ? (
                                <div><TypingEffect text={chat.text} onFinish={handleTypingEffectFinish} /></div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: chat.text }} />
                            )}
                        </div>
                    </div>
                </div>
            ))}
            {isLoading && (
                <div className="main_chat_data">
                    <div className="main_chat_data_userImg">
                        <img src={'https://i.pinimg.com/originals/76/b7/bf/76b7bfddee29f2bc0000cbae02b0c629.png'} alt="AviShifo" />
                    </div>
                    <div className="main_chat_data_chat">
                        <span className="main_chat_data_chat_head">AviShifo</span>
                        <div className="main_chat_data_chat_main">
                            <TypingEffect text="Typing..." onFinish={handleTypingEffectFinish} />
                        </div>
                    </div>
                </div>
            )}
            <div ref={chatEndRef} />
            <form 
                className="sendForm" 
                onSubmit={handleSendMessage} 
                style={{ opacity: isSending ? 0.5 : 1, cursor: isSending ? 'not-allowed' : 'default' }}
            >
                <label htmlFor="file-upload" className="custom-file-upload">
                    <MdOutlineAttachFile />
                </label>
                <input id="file-upload" type="file" onChange={handleFileChange} />
                <textarea
                    ref={textareaRef}
                    placeholder='Message AviShifo...'
                    value={message}
                    onChange={handleChange}
                    disabled={isSending}
                    style={{
                        overflow: 'hidden',
                        minHeight: '25px',
                        maxHeight: '200px',
                        resize: 'none',
                        height: '25px', // Initial height set to auto
                    }}
                />
                <button 
                    className={message !== '' && !isSending ? 'redim' : 'disabled-button'} 
                    type='submit' 
                    disabled={isSending || message === ''} 
                    style={{ cursor: (isSending || message === '') ? 'not-allowed' : 'pointer' }}
                >
                    <FaArrowUp />
                </button>
            </form>
        </div>
    );
}
