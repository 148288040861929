import { useEffect, useState } from 'react';
import './faq.css'
import { Link } from 'react-router-dom';

export default function Faq() {

    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

    useEffect(() => {
        localStorage.setItem('lang', lang);
    }, [lang]);

    const [loading, setLoading] = useState(false);
    const handleLangChange = (newLang) => {
        setLoading(true);
        setTimeout(() => {
            setLang(newLang);
            setLoading(false);
        }, 500); // Simulate loading delay
    };

    const content = {
        en: {
            nav: ['Home', 'About', 'FAQ'],
            lang: ['Uz', 'En', 'Ru'],
            routes: ['/home2', '/about', '/services'],
            faqTitle: 'Frequently Asked Questions',
            faqText: 'Answers to the most common questions about our platform.',
            questions: [
                {
                    title: 'How does the automatic diagnosis work?',
                    answer: 'Our system uses advanced machine learning algorithms that analyze symptoms and patient medical data to suggest possible diagnoses. This helps doctors make quick and accurate diagnoses.'
                },
                {
                    title: 'How to integrate AviShifo with my medical system?',
                    answer: 'We offer simple and secure ways to integrate our platform with your existing medical information system. Our support team will assist you with this process.'
                },
                {
                    title: 'What data does AviShifo collect?',
                    answer: 'AviShifo collects only the necessary data for diagnosis and treatment, including symptoms, medical history, and lab test results. All data is protected and stored according to the strictest security standards.'
                },
                {
                    title: 'How can I ensure the accuracy of the diagnosis suggested by AviShifo?',
                    answer: 'All recommendations and diagnoses suggested by the system are reviewed by qualified medical experts to ensure accuracy and reliability.'
                },
                {
                    title: 'How long is patient data stored on the platform?',
                    answer: 'Patient data is stored in the system for as long as it is needed for medical use, in accordance with legal requirements and security standards. You can also request data deletion at any time.'
                },
                {
                    title: 'What languages does AviShifo support?',
                    answer: 'The platform is available in multiple languages, including English, Russian, and Uzbek. We are continuously working on expanding language support.'
                },
                {
                    title: 'What should I do if I encounter technical problems?',
                    answer: 'If you experience technical issues, our support team is always ready to assist you. You can contact us through the feedback form on the website or by email: abdula_9146@icloud.com'
                },
                {
                    title: 'How can I update and edit patient information?',
                    answer: 'You can edit and update patient information at any time in your personal account on the platform. All changes are saved automatically.'
                }
            ],
        },
        ru: {
            nav: ['Главная', 'О нас', 'Вопросы и ответы'],
            lang: ['Uz', 'En', 'Ру'],
            routes: ['/home2', '/about', '/services'],
            faqTitle: 'Часто задаваемые вопросы',
            faqText: 'Ответы на самые распространенные вопросы о нашей платформе.',
            questions: [
                {
                    title: 'Как работает автоматическая диагностика?',
                    answer: 'Наша система использует передовые алгоритмы машинного обучения, которые анализируют симптомы и медицинские данные пациента, чтобы предложить возможные диагнозы. Это помогает врачам быстро и точно ставить диагноз.'
                },
                {
                    title: 'Как интегрировать AviShifo с моей медицинской системой?',
                    answer: 'Мы предлагаем простые и безопасные способы интеграции нашей платформы с вашей существующей медицинской информационной системой. Наша команда поддержки поможет вам с этим процессом.'
                },
                {
                    title: 'Какие данные собирает AviShifo?',
                    answer: 'AviShifo собирает только необходимые данные для диагностики и лечения, включая симптомы, медицинскую историю и результаты лабораторных тестов. Все данные защищены и хранятся в соответствии с самыми строгими стандартами безопасности.'
                },
                {
                    title: 'Как я могу убедиться в точности диагноза, предложенного AviShifo?',
                    answer: 'Все рекомендации и диагнозы, предлагаемые системой, проверяются квалифицированными медицинскими экспертами, чтобы гарантировать точность и надежность предложений.'
                },
                {
                    title: 'Как долго сохраняются данные пациентов на платформе?',
                    answer: 'Данные пациентов хранятся в системе столько, сколько необходимо для их медицинского использования, в соответствии с законодательством и стандартами безопасности. Вы также можете запросить удаление данных в любое время.'
                },
                {
                    title: 'Какие языки поддерживает AviShifo?',
                    answer: 'Платформа доступна на нескольких языках, включая английский, русский и узбекский. Мы постоянно работаем над расширением языковой поддержки.'
                },
                {
                    title: 'Что делать, если у меня возникли технические проблемы?',
                    answer: 'Если вы столкнулись с техническими проблемами, наша служба поддержки всегда готова помочь вам. Вы можете связаться с нами через форму обратной связи на сайте или по электронной почте: abdula_9146@icloud.com'
                },
                {
                    title: 'Как изменять и обновлять информацию о пациентах?',
                    answer: 'Вы можете в любой момент редактировать и обновлять информацию о пациентах в своем личном кабинете на платформе. Все изменения сохраняются автоматически.'
                }
            ],
        },
        uz: {
            nav: ['Bosh Sahifa', 'Biz haqimizda', 'Fikr-mulohaza'],
            lang: ['Uz', 'En', 'Ру'],
            routes: ['/home2', '/about', '/services'],
            faqTitle: "Ko'p beriladigan savollar",
            faqText: "Platformamiz haqida eng ko'p beriladigan savollarga javoblar.",
            questions: [
                {
                    title: "Avtomatik diagnostika qanday ishlaydi?",
                    answer: "Tizimimiz zamonaviy mashina o'rganish algoritmlaridan foydalanib, simptomlar va bemorning tibbiy ma'lumotlarini tahlil qiladi hamda mumkin bo'lgan tashxislarni taklif qiladi. Bu shifokorlarga tez va aniq tashxis qo'yishga yordam beradi."
                },
                {
                    title: "AviShifoni tibbiy tizimim bilan qanday integratsiya qilish mumkin?",
                    answer: "Bizning platformani mavjud tibbiy axborot tizimingiz bilan oson va xavfsiz tarzda integratsiya qilish uchun oddiy usullarni taklif qilamiz. Bizning yordamchi jamoamiz sizga bu jarayonda yordam beradi."
                },
                {
                    title: "AviShifo qanday ma'lumotlarni yig'adi?",
                    answer: "AviShifo tashxis va davolash uchun zarur bo'lgan ma'lumotlargina yig'iladi, jumladan, simptomlar, tibbiy tarix va laboratoriya test natijalari. Barcha ma'lumotlar eng qat'iy xavfsizlik standartlariga muvofiq himoya qilinadi va saqlanadi."
                },
                {
                    title: "AviShifo tomonidan taklif qilingan tashxisning to'g'riligiga qanday ishonch hosil qilish mumkin?",
                    answer: "Tizim tomonidan taklif qilingan barcha tavsiyalar va tashxislar malakali tibbiy ekspertlar tomonidan tekshiriladi, bu esa ularning to'g'riligi va ishonchliligini ta'minlaydi."
                },
                {
                    title: "Platformada bemor ma'lumotlari qancha vaqtgacha saqlanadi?",
                    answer: "Bemor ma'lumotlari tibbiy maqsadlar uchun zarur bo'lgan muddat davomida tizimda saqlanadi, qonun talablariga va xavfsizlik standartlariga muvofiq. Shuningdek, siz istalgan vaqtda ma'lumotlarni o'chirishni so'rashingiz mumkin."
                },
                {
                    title: "AviShifo qanday tillarni qo'llab-quvvatlaydi?",
                    answer: "Platforma bir necha tillarda, jumladan ingliz, rus va o'zbek tillarida mavjud. Biz doimo til qo'llab-quvvatlashni kengaytirish ustida ishlayapmiz."
                },
                {
                    title:  "Agar texnik muammolarga duch kelsam nima qilishim kerak?",
                    answer: "Agar siz texnik muammolarga duch kelsangiz, bizning yordamchi jamoamiz har doim sizga yordam berishga tayyor. Siz biz bilan saytimizdagi fikr-mulohaza formasi orqali yoki elektron pochta orqali bog'lanishingiz mumkin: abdula_9146@icloud.com"
                },
                {
                    title: "Bemor ma'lumotlarini qanday yangilash va tahrirlash mumkin?",
                    answer: "Platformadagi shaxsiy hisob qaydnomangizda bemor ma'lumotlarini istalgan vaqtda tahrirlashingiz va yangilashingiz mumkin. Barcha o'zgarishlar avtomatik ravishda saqlanadi."
                }
            ],
        }
    };

    return (
        <>
            <div className="homeGlav_nav">
                <a href="/" style={{ textDecoration: 'none' }}>
                    <h2>AviShifo</h2>
                </a>
                <ul>
                    {content[lang].nav.map((item, index) => (
                        <Link to={content[lang].routes[index]} key={index}>
                            <li>{item}</li>
                        </Link>
                    ))}
                    <div className="lang">
                        <span onClick={() => handleLangChange('uz')}>{content[lang].lang[0]}</span>
                        <span onClick={() => handleLangChange('en')}>{content[lang].lang[1]}</span>
                        <span onClick={() => handleLangChange('ru')}>{content[lang].lang[2]}</span>
                    </div>
                </ul>
            </div>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <div className='FaqText'>
                    <h2>{content[lang].faqTitle}</h2>
                    <p className='FaqTextP'>{content[lang].faqText}</p>
                    <div className="FaqParagraphs">
                        {content[lang].questions.map((faq, index) => (
                            <div className="FaqParagraph" key={index}>
                                <h3>{faq.title}</h3>
                                <p>{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}
