import logo from '../image/logoPng.png';
import { FiSearch } from "react-icons/fi";
import './navbar.css';
import { FaRegUser } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';

export default function Navbar({profilInfo ,  isOpenModal, setIsOpenModal, isOpenDrop, setIsOpenDrop }) {

  const accessToken = localStorage.getItem("accessToken")


  const navigate = useNavigate()

  function LogOut() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login')
  }



  return (
    <nav>
      <div className="navLeft">
        <Link to={'/'}><img src={logo} alt="Logo" /></Link>
        <ul>
          <li className='active'>
            <Link to="/home2">Home</Link>
          </li>
          <li>
            <Link to="https://t.me/communityavishifo">Community</Link>
          </li>
        </ul>
      </div>
      <div className="navRight">
        <div className="search">
          <FiSearch />
          <input placeholder='Search...' type="text" />
        </div>
        {
          !accessToken ? (
            <div className="loginForm">
              <Link to="/login"><button>Login</button></Link>
              <Link to="/register"><button>Register</button></Link>
            </div>
          ) : (
            <>
              <button onClick={() => isOpenDrop ? setIsOpenDrop(false) : setIsOpenDrop(true)} className='profilDropBtn'><FaRegUser /></button>
              <div className={isOpenDrop ? 'profilDropMenu' : 'none'}>
                <button onClick={() => setIsOpenModal(true)}><FaRegUser /> Profil</button>
                <button><IoSettingsOutline /> Setting</button>
                <button onClick={() => LogOut()}><BiLogOut /> LogOut</button>
              </div>
            </>
          )
        }
      </div>
      {
        isOpenModal ?
          (
            <div className="profilModal">
              <div className="profilModal_box">
                <div className="profilModal_box_head">
                  <p>{profilInfo[0].username}</p>
                  <button onClick={() => setIsOpenModal(false)}><IoMdClose /></button>
                </div>
                <div className="profilModal_box_userHeadInfo">
                  <div className="profilModal_box_userHeadInfo_img">
                    <img src={profilInfo[0].image} alt="" />
                  </div>
                  <div className="profilModal_box_userHeadInfo_info">
                    <h4>{profilInfo[0].fullname}</h4>
                    <p>{profilInfo[0].username}</p>
                    <p>{profilInfo[0].email}</p>
                    <p>{profilInfo[0].phone_number}</p>
                    <p>{profilInfo[0].gender}</p>
                  </div>
                </div>
                <div className="profilModal_box_main">
                  <label htmlFor="">About</label>
                  <p>{profilInfo[0].about}</p>
                  <label htmlFor="">MEMBER SINCE</label>
                  <p>June 2023</p>
                  <label htmlFor="">FAVORITES</label>
                  <p>Helping patients, Researching new treatments, Attending medical conferences</p>
                  <label htmlFor="">SKILLS</label>
                  <p>Diagnosis, Pharmacology, Patient care, Medical research</p>
                </div>
                <div className="profilModal_box_main_end">
                  <button>Edit Profile</button>
                  <button onClick={() => setIsOpenModal(false)} >Close</button>
                </div>
              </div>
            </div>
          ) : null
      }
    </nav>
  );
}
