import { Link } from 'react-router-dom';
import './about.css';
import ailogo from './../image/ai-logo.jpg'
import { useEffect, useState } from 'react';

export default function About() {

    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

    useEffect(() => {
        localStorage.setItem('lang', lang);
    }, [lang]);

    const [loading, setLoading] = useState(false);
    const handleLangChange = (newLang) => {
        setLoading(true);
        setTimeout(() => {
            setLang(newLang);
            setLoading(false);
        }, 500); // Simulate loading delay
    };

    const content = {
        en: {
            nav: ['Home', 'About', 'FAQ'],
            lang: ['Uz', 'En', 'Ru'],
            routes: ['/home2', '/about', '/services'],
            aboutTitle: 'ABOUT US',
            aboutText: [
                'AviShifo is a team of medical experts and technology specialists united by a common goal—to create an innovative platform that helps doctors of all specializations provide top-notch medical care. We strive to make healthcare more accessible, personalized, and efficient for every patient.',
                'We are proud that our platform is already being used by leading medical institutions and professionals around the world. Our mission is to provide every doctor with advanced tools for diagnosis and treatment so they can focus on what really matters—the health of their patients.',
                'Our team is continuously working on the development and improvement of AviShifo to offer the most modern and secure solutions for medical professionals. We believe that the future of medicine lies in close collaboration between doctors and the latest technologies, and AviShifo is at the forefront of this process.'
            ],
        },
        ru: {
            nav: ['Главная', 'О нас', 'Вопросы и ответы'],
            lang: [ 'Uz','En', 'Ру'],
            routes: ['/home2', '/about', '/services'],
            aboutTitle: 'О НАС',
            aboutText: [
                'AviShifo — это команда медицинских экспертов и технологических специалистов, объединенных общей целью — создать инновационную платформу, которая помогает врачам всех специализаций предоставлять медицинскую помощь на высшем уровне. Мы стремимся сделать медицину более доступной, персонализированной и эффективной для каждого пациента.',
                'Мы гордимся тем, что наша платформа уже используется ведущими медицинскими учреждениями и специалистами по всему миру. Наша миссия — предоставить каждому врачу передовые инструменты для диагностики и лечения, чтобы они могли сосредоточиться на том, что действительно важно — на здоровье пациентов.',
                'Наша команда непрерывно работает над развитием и улучшением AviShifo, чтобы предложить самые современные и безопасные решения для медицинских специалистов. Мы верим, что будущее медицины — это тесное сотрудничество между врачами и новейшими технологиями, и AviShifo стоит в авангарде этого процесса.'
            ],
        },
        uz: {
            nav: ['Bosh Sahifa', 'Biz haqimizda', 'Fikr-mulohaza'],
            lang: [ 'Uz','En', 'Ру'],
            routes: ['/home2', '/about', '/services'],
            aboutTitle: 'BIZ HAQIMIZDA',
            aboutText: [
                "AviShifo — bu turli soha shifokorlariga yuqori darajadagi tibbiy yordam ko'rsatishda yordam beruvchi innovatsion platformani yaratish uchun umumiy maqsad bilan birlashgan tibbiyot mutaxassislari va texnologiya mutaxassislaridan iborat jamoa. Biz sog'liqni saqlashni har bir bemor uchun yanada qulay, shaxsiylashtirilgan va samarali qilishga intilamiz.",
                "Bizning platformamiz allaqachon dunyoning yetakchi tibbiyot muassasalari va mutaxassislari tomonidan foydalanilayotganidan faxrlanamiz. Bizning missiyamiz — har bir shifokorga tashxis va davolash uchun ilg‘or vositalarni taqdim etish, shunda ular bemorlarning sog‘lig‘iga e'tibor qaratishi mumkin bo'ladi.",
                "Bizning jamoamiz AviShifoni rivojlantirish va takomillashtirish ustida tinimsiz ishlamoqda, shunda tibbiyot mutaxassislari uchun eng zamonaviy va xavfsiz yechimlarni taqdim etish mumkin. Biz tibbiyot kelajagi shifokorlar va zamonaviy texnologiyalar o'rtasidagi yaqin hamkorlikda ekanligiga ishonamiz va AviShifo ushbu jarayonning old qatorida turadi."
            ],
        }
    };

    return (
        <>
            <div className="homeGlav_nav">
                <a href="/" style={{ textDecoration: 'none' }}>
                    <h2>AviShifo</h2>
                </a>
                <ul>
                    {content[lang].nav.map((item, index) => (
                        <Link to={content[lang].routes[index]} key={index}>
                            <li>{item}</li>
                        </Link>
                    ))}
                    <div className="lang">
                        <span onClick={() => handleLangChange('uz')}>{content[lang].lang[0]}</span>
                        <span onClick={() => handleLangChange('en')}>{content[lang].lang[1]}</span>
                        <span onClick={() => handleLangChange('ru')}>{content[lang].lang[2]}</span>
                    </div>
                </ul>
            </div>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <div className="about">
                    <h1 className="about_title">{content[lang].aboutTitle}</h1>
                    <div className="about_box">
                        <div className="about_boximg">
                            <img src={ailogo} alt="AviShifo" />
                        </div>
                        <div className="aboutBox_texts">
                            {content[lang].aboutText.map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
