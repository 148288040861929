import { Link, useNavigate } from "react-router-dom";
import './login.css';
import { useEffect, useState } from "react";
import axios from 'axios';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const user_id = localStorage.getItem('user_id');

    const navigate = useNavigate();
    const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        if (accessToken) {
            navigate("/");
        }
    }, [accessToken, navigate]);

    const newChat = async (user_id, token) => {
        try {
            const response = await axios.post(`https://api.avishifo.uz/api/chat_histories/create_user_history/`,
                {
                    user_id: user_id
                },
                {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
            navigate(`/chat/${response.data.id}`);
        } catch (error) {
            console.error("Failed to create new chat:", error);
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://api.avishifo.uz/api/token/', {
                email,
                password
            });

            const { token, user_id } = response.data;
            localStorage.setItem('accessToken', token);
            localStorage.setItem('user_id', user_id);

            // Login successful, now create a new chat and navigate
            await newChat(user_id, token);

        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className="loginPage">
            <div className="loginPage_box">
                <div className="loginPage_box_head">
                    <h4>Login to AviShifo</h4>
                </div>
                <p className="loginTitle">Enter your email and password to access your account.</p>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleLogin}>
                    <label>Email</label>
                    <input
                        type="text"
                        placeholder="example@email.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="*******"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Login</button>
                </form>
                <div className="loginFooter">
                    <Link to={'/register'}><button>Register</button></Link>
                    <Link to={'/home2'}><button>Home</button></Link>
                    {/* <button>Forget Password?</button> */}
                </div>
            </div>
        </div>
    );
}
