import React, { useEffect, useState } from 'react';
import './home.css';
import { Link, useNavigate } from 'react-router-dom';

const HomeGlav = () => {


    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

    useEffect(() => {
        localStorage.setItem('lang', lang);
    }, [lang]);

    const [loading, setLoading] = useState(false);

    const handleLangChange = (newLang) => {
        setLoading(true);
        setTimeout(() => {
            setLang(newLang);
            setLoading(false);
        }, 500); // Simulate loading delay
    };

    const content = {
        en: {
            nav: ['Home', 'About', 'FAQ'],
            routes: ['/home2', '/about', '/services'],
            lang: ['Uz', 'En', 'Ru'],
            heroTitle: 'Welcome to AviShifo',
            heroSubtitle: 'An innovative medical platform designed to facilitate diagnosis and treatment using artificial intelligence.',
            learnMore: 'Begin',
            missionTitle: 'Our Mission',
            missionText: 'AviShifo aims to improve the quality of healthcare by integrating modern technologies into the daily practice of doctors. We offer tools for automating diagnosis and treatment, enhancing the accuracy and speed of decision-making.',
            featuresTitle: 'Key Features of AviShifo',
            features: [
                {
                    title: 'Automatic Diagnosis',
                    text: 'The system analyzes entered data and suggests possible diagnoses based on machine learning and clinical guidelines.'
                },
                {
                    title: 'Personalized Recommendations',
                    text: "Individual treatment recommendations based on the analysis of each patient's specific data."
                },
                {
                    title: 'Support for Doctors',
                    text: 'Feedback from medical experts to ensure the quality and safety of the provided recommendations.'
                }
            ],
            newsTitle: 'News and Updates',
            newsText: 'Stay informed about the latest developments and events in the world of medicine.',
            newsText1: 'Learn about the latest updates to our platform.',
            newsText2: 'Learn about the latest updates to our platform.',
            newsText3: 'Explore the latest medical research.',
            feedbackTitle: 'Feedback',
            feedbackText: 'Your opinion matters to us. Share your thoughts and suggestions.',
            footer: '© 2024 AviShifo. All rights reserved.',
        },
        ru: {
            nav: ['Главная', 'О нас', 'Вопросы и ответы'],
            routes: ['/home2', '/about', '/services'],
            lang: ['Uz', 'En', 'Ру'],
            heroTitle: 'Добро пожаловать в AviShifo',
            heroSubtitle: 'Инновационная медицинская платформа, разработанная для облегчения диагностики и лечения пациентов с использованием искусственного интеллекта.',
            learnMore: 'Начать',
            missionTitle: 'Наша миссия',
            missionText: 'AviShifo стремится улучшить качество медицинской помощи, внедряя современные технологии в повседневную практику врачей. Мы предлагаем инструменты для автоматизации диагностики и лечения, повышающие точность и скорость принятия решений.',
            featuresTitle: 'Основные возможности AviShifo',
            features: [
                {
                    title: 'Автоматическая диагностика',
                    text: 'Система анализирует введенные данные и предлагает возможные диагнозы, основываясь на машинном обучении и клинических рекомендациях.'
                },
                {
                    title: 'Персонализированные рекомендации',
                    text: 'Индивидуальные рекомендации по лечению, основанные на анализе данных каждого конкретного пациента.'
                },
                {
                    title: 'Поддержка врачей',
                    text: 'Обратная связь от экспертов-медиков для обеспечения качества и безопасности предоставляемых рекомендаций.'
                }
            ],
            newsTitle: 'Новости и обновления',
            newsText: 'Будьте в курсе последних разработок и событий в мире медицины.',
            newsText1: 'Узнайте о последних обновлениях нашей платформы.',
            newsText2: 'Присоединяйтесь к нашим обучающим онлайн-сессиям.',
            newsText3: 'Ознакомьтесь с последними медицинскими исследованиями.',
            feedbackTitle: 'Обратная связь',
            feedbackText: 'Ваше мнение важно для нас. Поделитесь своими мыслями и предложениями.',
            footer: '© 2024 AviShifo. Все права защищены.',
        },
        uz: {
            nav: ['Bosh Sahifa', 'Biz haqimizda', 'Fikr-mulohaza'],
            routes: ['/home2', '/about', '/services'],
            lang: ['Uz', 'En', 'Ру'],
            heroTitle: 'AviShifo ga xush kelibsiz',
            heroSubtitle: "Sun'iy intellekt yordamida diagnostika va davolash jarayonini osonlashtirish uchun yaratilgan innovatsion tibbiy platforma.",
            learnMore: 'Boshlash',
            missionTitle: 'Bizning vazifamiz',
            missionText: "AviShifo tibbiy xizmat sifatini oshirishga qaratilgan bo'lib, zamonaviy texnologiyalarni shifokorlar amaliyotiga integratsiya qiladi. Biz diagnostika va davolashni avtomatlashtirish uchun vositalarni taklif etamiz, bu esa qaror qabul qilishning aniqligi va tezligini oshiradi.",
            featuresTitle: 'AviShifo ning asosiy xususiyatlari',
            features: [
                {
                    title: 'Avtomatik diagnostika',
                    text: "Tizim kiritilgan ma'lumotlarni tahlil qilib, mashinasozlik va klinik tavsiyalar asosida mumkin bo'lgan tashxislarni taklif qiladi."
                },
                {
                    title: 'Shaxsiylashtirilgan tavsiyalar',
                    text: "Har bir bemorning o'ziga xos ma'lumotlarini tahlil qilish asosida individual davolash tavsiyalari."
                },
                {
                    title: "Shifokorlar uchun qo'llab-quvvatlash",
                    text: "Taqdim etilgan tavsiyalar sifatini va xavfsizligini ta'minlash uchun tibbiyot mutaxassislari tomonidan fikr-mulohazalar."
                }
            ],
            newsTitle: 'Yangiliklar va yangilanishlar',
            newsText: "Tibbiyot olamidagi so'nggi rivojlanishlar va tadbirlardan xabardor bo'ling.",
            newsText1: "Bizning platformamizdagi so'nggi yangilanishlar haqida ma'lumot oling.",
            newsText2: "Bizning o'quv onlayn sessiyalarimizga qo'shiling.",
            newsText3: "So'nggi tibbiy tadqiqotlar bilan tanishing.",
            feedbackTitle: 'Fikr-mulohaza',
            feedbackText: "Sizning fikringiz biz uchun muhim. O'zingizning fikrlaringiz va takliflaringizni ulashing.",
            footer: "© 2024 AviShifo. Barcha huquqlar himoyalangan.",
        }
    };

    const navigate = useNavigate()
    const accessToken = localStorage.getItem('accessToken')

    const handleLearnClick = () => {
        if (accessToken) {
            navigate('/'); // Redirect to the main page if logged in
        } else {
            navigate('/login'); // Redirect to the login page if not logged in
        }
    };

    // send massage telegram

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const telegramMessage = `
        Имя: ${name}
        Email: ${email}
        Сообщение: ${message}
        `;

        const token = '7084893676:AAHB5oABUhxTQAt1Oa5C8ihmVbFwGoBvHVQ';  // O'zingizning bot tokeningizni kiriting
        const chatIds = [984573662, 403167037];  // Bir nechta chat ID'ni massivga kiriting
        const url = `https://api.telegram.org/bot${token}/sendMessage`;

        // Har bir chat ID uchun xabar yuborish
        chatIds.forEach(async (chatId) => {
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: chatId,
                    text: telegramMessage,
                }),
            });
        });

        alert(lang === 'en' ? 'Message sent to all!' : 'Сообщение отправлено всем!');
    };


    return (
        <div className="homeGlav">
            <div className="homeGlav_nav">
                <a href="/" style={{ textDecoration: 'none' }}>
                    <h2>AviShifo</h2>
                </a>
                <ul>
                    {content[lang].nav.map((item, index) => (
                        <Link to={content[lang].routes[index]} key={index}>
                            <li>{item}</li>
                        </Link>
                    ))}
                    <div className="lang">
                        <span onClick={() => handleLangChange('uz')}>{content[lang].lang[0]}</span>
                        <span onClick={() => handleLangChange('en')}>{content[lang].lang[1]}</span>
                        <span onClick={() => handleLangChange('ru')}>{content[lang].lang[2]}</span>
                    </div>
                </ul>
            </div>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <>
                    <div className="homeGlav_box">
                        <h1>{content[lang].heroTitle}</h1>
                        <p>{content[lang].heroSubtitle}</p>
                        <button onClick={handleLearnClick}>{content[lang].learnMore}</button>
                    </div>
                    <div className="homeGlav_box">
                        <h1>{content[lang].missionTitle}</h1>
                        <p>{content[lang].missionText}</p>
                    </div>
                    <div className="homeGlav_box">
                        <h1>{content[lang].featuresTitle}</h1>
                        <div className="homeGlav_box_s">
                            {content[lang].features.map((feature, index) => (
                                <div key={index} className="homeGlav_box_s_box">
                                    <h3>{feature.title}</h3>
                                    <p>{feature.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="homeGlav_box">
                        <h1>{content[lang].newsTitle}</h1>
                        <p>{content[lang].newsText}</p>
                        <div className="homeGlav_box_s">
                            <div className="homeGlav_box_s_box">
                                <h3>{lang == 'ru' ? "Новые функции" : lang == 'uz' ? "Yangi funksiyalar" : "New Features"}</h3>
                                <p>{content[lang].newsText1}</p>
                            </div>
                            <div className="homeGlav_box_s_box">
                                <h3>{lang == 'ru' ? "Вебинары" : lang == 'uz' ? "Vebinarlar" : "Webinars"}</h3>
                                <p>{content[lang].newsText2}</p>
                            </div>
                            <div className="homeGlav_box_s_box">
                                <h3>{lang == 'ru' ? "Исследования" : lang == 'uz' ? "Tadqiqotlar" : "Research"}</h3>
                                <p>{content[lang].newsText3}</p>
                            </div>
                        </div>
                    </div>
                    <div className="homeGlav_form">
                        <h1>{content[lang].feedbackTitle}</h1>
                        <p>{content[lang].feedbackText}</p>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder={lang === 'en' ? 'Your Name' : 'Ваше имя'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <input
                                type="email"
                                placeholder={lang === 'en' ? 'Your Email' : 'Ваш email'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <textarea
                                placeholder={lang === 'en' ? 'Your Message' : 'Ваше сообщение'}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <button type="submit">{lang === 'en' ? 'Send' : 'Отправить'}</button>
                        </form>
                    </div>
                    <div className="homeGlav_footer">
                        <p>{content[lang].footer}</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default HomeGlav;
