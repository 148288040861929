import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Chat from './pages/Chat';
import Layout from './pages/Layout';
import axios from 'axios';
import { useState, useEffect } from 'react';
import HomeGlav from './home/HomeGlav';
import About from './About/About';
import Faq from './Faq/Faq';

function App() {
    const accessToken = localStorage.getItem('accessToken');
    const user_id = localStorage.getItem("user_id");
    const [history, setHistory] = useState([]);
    const navigate = useNavigate();
    const location = useLocation(); // Track the current path

    const getHistory = async () => {
        try {
            const response = await axios.get(`https://api.avishifo.uz/api/histories/?user_id=${user_id}`, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                }
            });
            setHistory(response.data);
        } catch (error) {
            console.error("Error fetching history:", error);
        }
    };

    useEffect(() => {
        // Fetch chat history when the user logs in
        if (accessToken && user_id) {
            getHistory();
        }
    }, [accessToken, user_id]);

    useEffect(() => {
        // Only redirect to the first chat if the user is on the root path ('/')
        if (accessToken && history.length > 0 && location.pathname === "/") {
            navigate(`/chat/${history[0].id}`);
        }
    }, [history, navigate, accessToken, location.pathname]);

    const PrivateRoute = ({ children }) => {
        return accessToken ? children : <Navigate to="/login" />;
    };

    return (
        <div className="App">
            <Routes>
                {/* Public Routes */}
                <Route path='/home2' element={<HomeGlav />} />
                <Route path='/about' element={<About />} />
                <Route path='/services' element={<Faq />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Private Routes */}
                <Route path="/" element={
                    <PrivateRoute>
                        <Layout history={history} getHistory={getHistory} />
                    </PrivateRoute>
                }>
                    <Route path="chat/:historyId" element={<Chat getHistory={getHistory} />} />
                    {/* Ensure that navigating to root redirects to the first chat only */}
                    <Route index element={<Navigate to={history.length > 0 ? `/chat/${history[0].id}` : '/'} />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
